.website__background {
    background-color: #ECF0EE;
}
.website__header {
    font-size: 30px;
    font-family: "Manrope-Bold" !important;
    padding-top: 20px;
    color: #51433e;
}
.website__header-subtitle {
    font-size: 14px;
    font-family: "Manrope-Regular" !important;
    padding-bottom: 15px;
    color: #51433e;
}