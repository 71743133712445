.offers__table {
    background-color: white;
    width: 100%;
    padding: 5px;
    border-radius: 16px;    
}

.offers__table th {
    background-color: #f6f8eb;
    font-size: 14px;
    font-family: "Manrope-SemiBold" !important;
    height: 38px;
    padding-left: 15px;
}
.offers__table tr {
    height: 54px;
    padding-left: 15px;    
}

.offers__table td {
    padding-left: 15px;
    font-family: "Manrope-Regular" !important;
    font-size: 14px;
}

tr:nth-child(even) {background-color: #f7f8f4;}

.offers__table-box {
    background-color: white;
    padding: 15px !important;
    border-radius: 16px;
    margin-top: 30px !important;    
}

a {
    text-decoration: none !important;
    color: black !important;
    font-family: "Manrope-SemiBold" !important;
    font-size: 14px;
}

a:active {
    color: #B6BF00 !important;
    background-color: none;
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: none !important;
}

.offers__delete-icon {
    width: 13.5px;
    height: 14.06px;
    margin-right:10px;
    margin-top: -2px;
}

.offers__edit-icon {
    width: 14.06px;
    height: 14.06px;
    margin-right:10px;
    margin-top: -2px;
}

.offers_edit-hr {
    margin-top: 8px;
    margin-bottom: 8px;
}

.offers__create-button {
    float: right;
    margin-top: 20px;
    margin-right: 10px;
    width: 150px;
}

.pageLink {
    width: 32px;
    height: 32px;
    border: 1px solid #e4edea;
    border-radius: 4px;
    float: left;
    text-align: center;
    padding-top: 2px;
    margin-right: 10px;
    cursor: pointer;
}

.pageLink.active {
    border: 1px solid #00502f;
}

.pageLink.disabled {
    border: 1px solid #e4edea;
    background-color: #e5ecea;
    cursor: default;
}

.dropdown-menu {
    --bs-dropdown-min-width: 25px !important;
}

.users__search-field {
    float: right;
    margin-top: 35px;
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 35px;
    padding-left: 10px;
}

.users_input {
    padding: 12px !important;
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: "Manrope-Medium" !important;
    font-size: 14px !important;
    padding-right: 2.5rem !important;
    float: right;
    width: 250px;
  }
  .users_input-wrapper {
    position:relative;
    width: 250px;
    float: right;
  }
  
  .users_input-icon {        
    padding: 4px;
    padding-left: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: 1px;
    right:12px;
    transform: translateY(-50%);
  }
  
  .users_input-icon-after {        
    padding: 4px;
    padding-right: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: -1px;
    right:2px;
    transform: translateY(-50%);
  }