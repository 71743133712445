.login__background {
    background: #ECF0EE;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  
  .div-center {
    width: 500px;
    height: 500px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 1em 2em;
    border-bottom: 2px solid #ccc;
    display: table;
    border-radius: 16px;
  }
  
  div.content {
    display: table-cell;
    vertical-align: middle;
  }

  .login__logo {
    padding-bottom: 50px;
  }
  .login__button {
    width: 100%;
    padding: 12px !important;
    margin-top: 10px;
  }
  .login_input {
    padding: 12px !important;
    margin-bottom: 15px;
    font-family: "Manrope-Medium" !important;
    font-size: 14px !important;
    padding-left: 2.5rem !important;
  }



  .login_input-wrapper {
    position:relative;
  }
  
  .login_input-icon {        
    padding: 4px;
    padding-left: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: -1px;
    left:2px;
    transform: translateY(-50%);
  }
  
  .login_input-icon-after {        
    padding: 4px;
    padding-right: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: -1px;
    right:2px;
    transform: translateY(-50%);
  }
  

  .input {
    height: 50px;
    box-sizing:border-box;
    padding-left: 2.5rem;
  }
