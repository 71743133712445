.offer-form__box {
    background-color: white;
    border-radius: 16px;
    padding: 20px;
}
.offer-form__label {
    font-family: "Manrope-SemiBold" !important;
    font-size: 14px;
    padding-bottom: 8px;
}
.offer-form__input {
    width: 100%;
    margin-bottom: 20px;
}
.offer-form__cancel-button {
    float: right;
    width: 100px;
}
.offer-form__save-button {
    margin-left: 15px;
    width: 100px;
    float: right;
}
.offer-form__dropzone {
    background-color: #fbfcf0;
    width: 100%;
    height: 150px;
    border-radius: 8px;    
    border: 1px dashed #b5ce2f;
    margin-bottom: 20px;    

    display: flex;
    align-items: center;
}

.offer-form__dropzone-text {
    margin: auto;
    width: 100px;
    text-align: center;
    height: 60px;
    color: #00502f;
    font-family: "Manrope-Regular" !important;
    font-size: 14px;
}