body {
    background-color: #ecf0ee !important;
}
h2 {
    font-size: 28px !important;
    padding-bottom: 15px;
}

h3 {
    font-size: 22px !important;
}