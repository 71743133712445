img.sidebar__logo {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 15px;
}
div.sidebar__logo-container {
    width:100%;
    text-align: center;
}
.sidebar__container {
    background-color: black !important;
    /* border-radius: 30px; */
   /* margin: 20px !important; */
/*    margin-left: 20px !important;
    margin-right: 20px !important;*/
    color: #B6BF00;    
}
.sidebar__hr {
    height: 1px;
    color: #ffffff;
    width: 100%;
    opacity: 20%;
    margin-bottom: 45px;
}
.nav-pills {
    color: #B6BF00;
    padding: 15px;
    border-radius: 8px;
    padding-left: 20px;
}
.sidebar__offers-icon {
    width:18.75px;
    height:19.41px;
    margin-right: 10px;
    margin-top: -3px;
    margin-left: 15px
}
.sidebar__logout-link {
    color: #ff4d4f
}
.sidebar__link {
    cursor: pointer;
}